.heroTitle {
    display: flex;
    font-family: 'Palanquin', sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    color: #1D1D35;
    width: 100%;
    height: 256px;
}