.examCardImageBannerContainer {
    width: 379px;
    height: 160px;
    border-radius: 24px 24px 0 0;
}

.examCardImageBanner {
    width: 379px;
    height: 160px;
    border-radius: 24px 24px 0 0;
    object-fit: cover;
}