.postDateContainer {
    margin-top: 104px;
    width: 100%;
    height: 16px;
}

.postDateH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(29, 29, 53, 0.5);
}