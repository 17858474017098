.examButtonContainer {
    width: 71px;
    height: 24px
}

.examButtonH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #008752;
}

.examButtonH1:hover {
    cursor: pointer;
    transform: scale(1.1)
}

.examButtonH1:active {
    opacity: 0.8;
}