.postDescriptionContainer {
  margin-top: 56px;
  width: 40%;
  height: 392px;

}

.postDescriptionText {
  font-family: 'Palanquin', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(29, 29, 53, 0.5);
}

