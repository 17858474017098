.successfullyModalBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Ajuste a opacidade do fundo conforme necessário */
    z-index: 100;
}

.successfullyModalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 496px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 16px; /* Ajuste o raio do border-radius conforme necessário */
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra suave */
    position: relative;
}

.CloseButton {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 101;
}

.successfullyModalH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 32px;
    line-height: 40px; /* Ajuste conforme necessário */
    font-weight: 700;
    text-align: center;
    color: #1D1D35;
    margin-bottom: 20px; /* Ajuste conforme necessário */
}

.successfullyModalP {
    font-family: 'Palanquin', sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
    color: rgba(29, 29, 53, 0.5);
}