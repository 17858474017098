.ExamTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  height: 40px;
}

.ExamTitleH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
}