.heroDescription {
    display: flex;
    font-family: 'Palanquin', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D1D3580;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 48px;

}