.modalBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* Ajuste a opacidade do fundo conforme necessário */
    z-index: 100;
}

.modalContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 80%;
    height: 600px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 16px;
    cursor: default;
    /* Ajuste o raio do border-radius conforme necessário */
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    /* Adiciona uma sombra suave */
    position: relative;
}

.modalinformationContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 725px;
    height: 100%;
    gap: 34px;
}

.modalTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 65%;
    height: 100%;

}

.modalPreparationTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 35%;
    height: 100%;
    gap: 16px;
}

.modalButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 165px;
    height: 48px;
    padding: 12px 24px;
    border-radius: 16px;
    background-color: #00C6FF;
    cursor: pointer;
}

.modalButton {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #1D1D35;
    width: 100%;
    cursor: pointer;
}

.CloseButton {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 101;
}

.modalH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 32px;
    line-height: 40px;
    /* Ajuste conforme necessário */
    font-weight: 700;
    text-align: center;
    color: #1D1D35;
    margin-bottom: 20px;
    text-align: left;
    /* Ajuste conforme necessário */
}

.modalP {
    font-family: 'Palanquin', sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
    color: rgba(29, 29, 53, 0.5);
    text-align: left;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 347px;
    height: 100%;
}

.formModalH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #1D1D35;
}

.modalPreparationH2 {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #1D1D35;
}

.inputArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 48px;
}

.inputObsArea {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 136px;
}

.inputField {
    font-family: 'Palanquin', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 29, 53, 0.3);
    padding: 16px;
    border: 1px solid rgba(29, 29, 53, 0.08);
    border-color: rgba(29, 29, 53, 0.08);
    border-radius: 16px;
    width: 100%;
    height: 100%;
}

.formButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    background-color: #00C6FF;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
}

.modalButtonContainer:hover {
    background-color: #00A3D9;
    cursor: pointer;
}

.formButton:hover {
    background-color: #00A3D9;
    cursor: pointer;
}