.examCardTitleContainer {
    width: 331px;
    height: 48px;
}

.examCardTitle {
    font-family: 'Palanquin', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D1D35;
    
}