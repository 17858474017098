* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #F3FEFF;
}

@import url('https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap');

