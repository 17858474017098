.examsContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 10%;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    margin-top: 166px;
}

.examsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-right: 10%;
    margin-bottom: 40px;
    height: 40px;
}

.examsFooter {
    cursor: grab;
}

.examsContent {
    max-width: calc(100vw - 10vw);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    gap: 32px;
}