.blogCardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 24px;
    width: 100%;
    height: 432px;
}

.blogCardTop {
    width: 100%;
    height: 280px;
    border-radius: 24px;
}

.blogCardBottom {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 0 0 24px 24px;
    padding: 24px 15px;
}

.blogCardContainer:hover {
    transition: transform 1s ease;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
}