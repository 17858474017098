.aboutFiguresContainer {
    display: flex;
    flex-direction: row;
    width: 58%;
    height: 696px;
}

.leftSideAboutFiguresContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.leftSideAboutImgTop {
    height: 50%;
    width: 100%;
    object-fit: cover;
}

.leftSideAboutBottomContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50%;
}

.leftSideAboutImgBottom {
    height: 100%;
    width: 50%;
    object-fit: cover;
}


.rightSideAboutFiguresContainer {
    width: 50%;
    height: 100%;
}

.rightSideAboutImg {
    height: 100%;
    width: 100%;
    border-radius: 0px 999px 999px 0px;
    object-fit: cover;
}