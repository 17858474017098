.examConsultationContainer {
    display: flex;
    flex-direction: row;
    width: 55%;
    padding: 24px;
    margin: 0 auto;
    margin-top: 88px;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 38px 80px 0px #12063212;
}