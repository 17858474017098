.homeNavButtonContainer {
    border-radius: 16px;
    position: relative;
}

.homeNavButton {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #1D1D35;
    text-decoration: none;
}

.homeNavButtonLine {
    background-image: linear-gradient(to right, black 80%, transparent 80%);
    /* Criando um gradiente linear que vai de preto a transparente */
    background-repeat: no-repeat;
    /* Evita a repetição do gradiente */
    background-size: 0% 2px;
    /* Define o tamanho inicial do sublinhado */
    transition: background-size 1s;
}

.homeNavButtonContainer::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 0;
    height: 2.5px;
    background: #00C6FF; /* Altere para a cor desejada */
    transition: width 1s;
}

.homeNavButtonContainer:hover::after {
    width: 50%;
}