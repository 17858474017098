.examCardContainer {

    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 379px;
    height: 560px;
    border-radius: 24px;
}

.examCardHeader {
    width: 100%;
    height: 160px;
    border-radius: 24px 24px 0 0;
}

.examCardBottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    height: 400px;
    padding: 0 24px 0 24px;
    border-radius: 0 0 24px 24px;
    margin-top: 24px;
    margin-bottom: 24px;
}