.ContactUsSocialMediaContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.facebookIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.instagramIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}