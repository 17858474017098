.examConsultationLabelContainer {
    width: 40%;
    height: 48px;
    min-width: 313px;
}

.examConsultationLabel-normal {
    font-family: 'Palanquin', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D1D35;
    height: 100%;
}

.examConsultationLabel-strong {
    font-family: 'Palanquin', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D1D35;
    height: 100%;
}