.copyrightContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 24px;
}

.copyrightLabel {
    font-family: 'Palanquin', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #FFFFFF
}

.copyrightStrong {
    font-family: 'Palanquin', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #FFFFFF;
    text-decoration: underline;
}