.heroFiguresContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 57%;
    height: 100%;
}

.roundedImagesContainer {
    width: 50%;
    height: 100%;
}

.roundedImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.squareImagesContainer {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 100%;
}

.leftSquareImages {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.roundedSquareFromLeftSide {
    width: 100%;
    height: 50%;
    border-radius: 50% 50% 0 0;
    background-color: #008752;
}

.roundedImgFromLeftSide {
    width: 100%;
    height: 50%;
}

.rightSquareImages {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.roundedImgFromRightSide {
    width: 100%;
    height: 50%;
    border-radius: 50%;
    background-color: #1D1D35;
}

.squaredImgFromRightSide {
    width: 100%;
    height: 50%;
    background-color: #DDFCFF;
}