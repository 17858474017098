.navBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 48px;
  width: 80%;
  margin: 0 auto;
  margin-top: 80px;
}

.navBarLogoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  width: 50%;
}

.navBarButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 50%;
}