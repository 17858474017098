.contactUsAddressContainer {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
}

.contactUsAddressLabel {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    width: 140px;
    height: 48px;
}