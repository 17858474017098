.examDropDownInputContainer {
  display: flex;
  align-items: center;
  border-radius: 16px;
  min-width: 174px;
  height: 48px;
  padding: 12px 16px;
  background-color: #ffffff;
  }

  .select-input {
    width: 100%;
    font-family: 'Palanquin', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    border: 0px;
    color: rgba(29, 29, 53, 0.3);
    appearance: none; /* Remove o estilo padrão do navegador */
    -webkit-appearance: none; /* Para navegadores baseados em WebKit (como Chrome, Safari) */
    -moz-appearance: none; /* Para navegadores baseados em Gecko (como Firefox) */
    background-image: url("../../assets/svg/DropDownArrow.svg"); /* Define uma imagem personalizada para a seta */
    background-repeat: no-repeat;
    background-position: right center; /* Posiciona a seta à direita */
  }

  .optionLabel {
    font-family: 'Palanquin', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 29, 53, 0.3);
  }
  