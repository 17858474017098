.blogTitleHomeContainer {
    width: 84px;
    height: 40px;
}

.blogTitleHomeH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D1D35;
}