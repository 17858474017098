.examCardPreparationTitleContainer {
    width: 331px;
    height: 24px;
}

.examCardPreparationTitle {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #1D1D35;
}