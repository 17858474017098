.exam-consultation-button-area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    height: 48px;
    background-color: #00C6FF;
    border-radius: 16px;
    padding: 12px 24px;
    min-width: 259px;
}

.exam-consultation-button {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    text-wrap: none;
    color: #1D1D35;
}

.exam-consultation-button-area:hover {
    background-color: #00A3D9;
    cursor: pointer;
}

.exam-consultation-button:hover {
    cursor: pointer;
}