.postImageBannerContainer {
    margin-top: 80px;
    width: 1197px;
    height: 480px;
}

.postImageBanner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px
}