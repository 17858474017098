.search-container {
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: 48px;
  padding: 12px 16px;
  background-color: #ffffff;
}

.search-input {
  font-family: 'Palanquin', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: 0px;
}

.search-input::placeholder {
  font-family: 'Palanquin', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(29, 29, 53, 0.3);
}

.search-input:focus {
  outline: none;
}

.search-button {
  cursor: pointer;
}