.footerTitleContainer {
    width: 100%;
    height: 16px;
    margin-bottom: 40px;
}

.footerTitleContainer {
    font-family: 'Palanquin', sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #FFFFFF;
    text-wrap: nowrap;
}