.footerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 464px;
    padding: 120px 120px 0 120px;
    border-radius: 136px 0px 0px 0px;
    background-color: #1D1D35;
    margin-top: 152px;
    justify-content: space-between;
    position: relative;
}

.footerNavigationContainer {
    display: flex;
    flex-direction: row;
}

.footeLeftSideContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.footerRightSideContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
    text-align: left;
}

.whatsAppIcon {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 0;
    z-index: 999;
}

.whatsAppIcon:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.whatsAppIcon:active {
    transform: scale(1);
    opacity: 0.8;
}