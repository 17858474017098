.blogCardDateContainer {
    width: 100%;
    height: 16px;
}

.blogCardDateH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 29, 53, 0.5);
}