/* PaginationImages.css */
.pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.image-grid img {
    width: 100%;
    height: auto;
}

.pagination-controls {
    margin-top: 10px;
    gap: 10px;
}

.pagination-controls button {
    margin: 0 5px;
}

.activePage {
    width: 55px;
    height: 48px;
    padding: 12px 24px;
    border-radius: 16px;
    background-color: #00C6FF;
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    border: none;
}

.disabledPage {
    width: 55px;
    height: 48px;
    padding: 12px 24px;
    border-radius: 16px;
    background-color: rgba(0, 198, 255, 0.2);
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: center;
    border: none;
}