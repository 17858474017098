.bgImageContainer {
    position: absolute;
    width: 861px;
    height: 819px;
    left: 0px;
    top: 0px;
    
    background: #DDFCFF;
    border-radius: 0px 0px 500px 0px;
    z-index: -1;
}

