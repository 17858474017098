.aboutContentsContainer {
    display: flex;
    flex-direction: column;
    width: 42%;
    height: 100%;
    background-color: #DDFCFF;
    border-radius: 160px 0px 0px 0px;
    margin: 0 auto;
    padding: 0px 4%;
    padding-top: 64px;
}