.contactUsPhoneContainer {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
}

.contactUsPhoneLabel {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF
}