.aboutSubTitleContainer {
    width: 100%;
    margin-top: 32px;

}

.aboutSubTitleLabel {
    font-family: 'Palanquin', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D1D35;
}