.postTitleContainer {
    margin-top: 8px;
    width: 789px;
    height: 128px;
}

.postTitle {
    font-family: 'Palanquin', sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    color: #1D1D35;
}