.blogCardTitleContainer {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 48px;
}

.blogCardTitleH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D1D35;
}