.footerButtonContainer {
    height: 16px;
    margin-bottom: 8px;
}

.footerButton {
    font-family: 'Palanquin', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    text-decoration: none;
}

.footerButton:hover {
    cursor: pointer;
    color: #00C6FF;
    transform: scale(1.1);
    transition: 1s ease;
}