.blogTitleContainer {
    width: 379px;
    height: 128px;
    width: 80%;
    text-align: left;
    text-wrap: wrap;
    margin: 0 auto;
    margin-top: 128px;
}

.blogTitleH1 {
    font-family: 'Palanquin', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #1D1D35;
}